import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import '../Styles/PopupWindowStyles.scss';
import useWindowDimensions from '../Helpers/UseWindowDimensions';

const PopupWindow = ({
  // callback = the callback to be used when one of the buttons on the popup window is clicked
  callback,
  // className = error or confirm, which type of popup we are displaying (will be used for logic and styling)
  className,
  // message = the message to display
  message,
  // title = the title at the top of the popup
  title,
  // display = whether we should display the popup or not
  display,
}) => {
  /**
   * function to act as a callback handler. Only responsibilty is to call the callback
   * while passing in the successValue (either true or false)
   * @param {Boolean} successValue
   */
  const handleCallback = (successValue) => {
    callback(successValue);
  };
  // use the useSpring hook, provided by react spring to generate style props depending on whether we are displaying the popup
  // or not. This gives the popup window an animated appearance when it appears/disappears
  const props = useSpring({
    opacity: display ? 1 : 0,
    top: display ? '0px' : '100%',
    display: display ? 'grid' : 'none',
  });

  // RENDER
  return (
    // animated.div is provided by the spring library to be used as a container for the animated props defined above
    <animated.div
      className="popup_bg"
      style={{
        ...props,
        zIndex: 12,
      }}
    >
      <div
        className={`popup_window ${className}`}
        style={{
          width: '400px',
          minHeight: 200,
          // position: 'absolute',
        }}
      >
        <div className="title">
          <p>{title}</p>
        </div>
        <div
          className="content"
          style={{
            overflow: 'scroll',
          }}
        >
          <p>{message}</p>
        </div>
        {/* if we are not using the error popup then display the yes/no buttons and assign their onClick functions appropriately */}
        {className !== 'error' && (
          <div className="buttons">
            <button
              type="button"
              className="confirm"
              onClick={() => handleCallback(true)}
            >
              yes
            </button>
            <button
              type="button"
              className="decline"
              onClick={() => handleCallback(false)}
            >
              no
            </button>
          </div>
        )}
        {/* if we are showing the error popup then we just need to display a close button with a callback handler passing in false */}
        {className === 'error' && (
          <div className="buttons">
            <button
              type="button"
              className="decline"
              onClick={() => handleCallback(false)}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </animated.div>
  );
};

PopupWindow.propTypes = {
  className: PropTypes.string.isRequired,
  callback: PropTypes.func,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  display: PropTypes.bool.isRequired,
};

PopupWindow.defaultProps = {
  callback: () => {},
};

export default PopupWindow;
