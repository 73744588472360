import React from 'react';
import '../Styles/404NotFoundStyles.scss';
// component to render if someone tries to access the maps without api key or by typing in a wrong url (should never realistically
// be seen, but added so that a random person couldnt just try to access it without permission
const NotFound = () => (
  <div id="not_found">
    <h1>Oops...</h1>
    <h2>
      We were unable to find the page you requested. Are you
      authorised to view?
    </h2>
    <h3 className="not_found_label">
      ({process.env.REACT_APP_ENV_MODE})
    </h3>
  </div>
);

export default NotFound;
