import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from './Inputs/DropDownMenu';
import '../Styles/FiltersStyles.scss';
import Checkbox from './Inputs/Checkbox';
import TextInput from './Inputs/TextInput';
import DatePicker from './DatePicker/DatePicker';
import RefreshButton from './RefreshButton';
import { getNetsuiteURL, makeRequest } from '../Helpers/Requests';
import MapBlocker from './MapBlocker';
import ContentBlocker from './ContentBlocker';
import AssignButton from './AssignButton';
import PopupWindow from './PopupWindow';
import MultiSelect from './Inputs/MultipleSelect';

const Filters = ({
  // markers to display
  markers,
  // function to update the list of markers
  setData,
  // current map
  map,
  // cases selected via polygon
  selectedCases,
  // current polygon on map
  currentPolygon,
  // which filters to always display
  displayFilters,
  // filters to only display if there are markers on the map
  markerDisplayFilters,
  // the options to display in the access dropdown meny
  accessOptions,
  // type of map - cleanup, team_assignment, cold_call
  mapType,
  // whether the white click blocker screen should be displayed
  processing,
  // update whether we should show the click blocker
  setProcessing,
  // type of refresh, depending on the last refresh button pressed - basic, full
  refreshType,
  // function to update the type of refresh
  setRefreshType,
}) => {
  // set initial state [value, setValue function]
  // openFilter =  which filter is currently open (only matters for drop down menus)
  const [openFilter, setOpenFilter] = useState('');
  // localProcessing = local processing variable - disregard (exists in code, but doesn't make a difference)
  const [localProcessing, setLocalProcessing] = useState(false);
  // engineers = list of engineers to display in the engineers drop down
  const [engineers, setEngineers] = useState([]);
  const [engineersToSelect, setEngineersToSelect] = useState([]);
  /**
   * @param {String} popupMessage - message to display in popup body
   * @param {String} popupClassName - classname to determine the color of the popup. values can be error or confirm
   * @param {String} popupTitle - title to display at top of popup
   * @param {Boolean} display - whether popup should be displayed
   * @param {Function} callback - callback function to execute when the popup button is clicked
   */
  const [popupData, setPopupData] = useState({
    popupMessage: '',
    popupClassName: '',
    popupTitle: '',
    display: false,
    callback: null,
  });
  const {
    popupMessage,
    popupTitle,
    popupClassName,
    display,
    callback,
  } = popupData;
  // teams to display in the teams dropdown menu
  const [teams, setTeams] = useState([]);
  const [teamsToAssign, setTeamsToAssign] = useState([]);

  /**
   * @param {String} access - access selected in the access dropdown menu - default value is the first access option
   *                          passed in via props
   * @param {Boolean} includeAssigned - value selected in the include assigned checkbox - default value is true if
   *                                    the map is cold_call, else is false
   * @param {string} poNumber - singular PO number - no default value
   * @param {Date} date - date selected in the date picker dropdown - no default value
   * @param {String} engineer - singular engineer selected in engineer dropdown - default value is DEFAULT as this will
   *                            be the first value added to the dropdown menu when the list is initially fetched
   * @param {String} team - team selected in the dropdown - default value is DEFAULT as this will
   *                        be the first value added to the dropdown menu when the list is initially fetched
   * @param {String[]} poNumbers - list of PO numbers selected in the multi select PO field
   * @param {String[]} selectedEngineers - list of engineers selected in the multiple select engineer menu
   */
  const [filters, setFilters] = useState({
    access: accessOptions[0].value,
    includeAssigned: mapType === 'cold_call',
    poNumber: '',
    // poNumber: '3500118252_TEST',
    date: null,
    engineer: 'DEFAULT',
    team: 'DEFAULT',
    poNumbers: [],
    selectedEngineers: [],
    customerCode: '',
    plannerGroup: '',
    teamToAssign: 'DEFAULT',
    configCode: '',
    secondWave: null,
  });
  const {
    access,
    includeAssigned,
    poNumber,
    date,
    team,
    engineer,
    poNumbers,
    selectedEngineers,
    customerCode,
    plannerGroup,
    teamToAssign,
    configCode,
  } = filters;
  // default option for engineer to be used when adding the first engineer
  // in dropdown to be the default option
  const defaultEngineer = {
    value: 'DEFAULT',
    text: 'Select Engineer...',
  };

  // object used to map the correct list of options to the id of the menu
  const filterOptionMapping = {
    engineer: engineers,
    selectedEngineers: engineersToSelect,
    team: teams,
    teamToAssign: teamsToAssign,
    access: accessOptions,
    noCases: selectedCases.length,
    date,
    poNumbers,
    configCode,
  };

  /**
   * function to handle field value changes
   * @param {Object|String|Number} value - the value to set
   * @param {String} id - id of the field to set - will be used to map the value to the correct
   *                      key in the above filters object
   */
  const handleChange = (value, id) => {
    // if the value is an array/object but is not a date field then destructure the current filters and destructure the
    // object and set these values
    if (typeof value === 'object' && id !== 'date') {
      const newFilters = {
        ...filters,
        ...value,
      };
      setFilters(newFilters);
      // else use the id to set that specific key's value
    } else {
      setFilters({
        ...filters,
        [id]: value,
      });
    }
  };
  /**
   *NOTE: function to handle change were fetchinig data is also required
   * @param value
   * @param id
   * @return {Promise<void>}
   */
  const fetchEngineersByTeam = async (value, id) => {
    const setterFunction =
      id === 'teamToAssign' || mapType !== 'cold_call'
        ? setEngineers
        : setEngineersToSelect;
    const engineerKey =
      id === 'teamToAssign' || mapType !== 'cold_call'
        ? 'engineer'
        : 'selectedEngineers';
    const engineerDefaultValue =
      id === 'teamToAssign' || mapType !== 'cold_call'
        ? 'DEFAULT'
        : [];
    // set value of field that has been changed
    // set processing to true, populate engineers with default and assign engineer as default
    handleChange({
      [id]: value,
      [engineerKey]: engineerDefaultValue,
    });
    setProcessing(true);
    setterFunction([defaultEngineer]);
    // if a default value has been selected, just remove the white click blocker and stop processing
    if (value === 'DEFAULT') {
      setProcessing(false);
      return;
    }
    // request the data for engineers in Netsuite
    const { data } = await makeRequest({
      body: {
        url: `${getNetsuiteURL()}&functionName=getEngineers`,
        team: value,
      },
    });
    // add the default engineer to the list of engineers
    const engineersToSet = [defaultEngineer, ...data];
    // set engineer and set processing to false to allow data viewing
    setterFunction([...engineersToSet]);
    // console.log('[ENGINEERS TO SET]', engineersToSet);
    setFilters({
      ...filters,
      [engineerKey]: engineerDefaultValue,
      [id]: value,
    });
    setProcessing(false);
  };

  // object to map the type of change required for each input to the correct change function above
  const handleChangeMapping = {
    normal: handleChange,
    fetchEngineers: fetchEngineersByTeam,
  };
  // console.log('[ENGINEERS]', engineers);

  /**
   * function to fetch the list of teams from netsuite
   * @returns {Promise<void>}
   */
  const fetchTeams = async () => {
    // throw up click blocker
    setProcessing(true);
    // request the data from netsuite
    const { data } = await makeRequest({
      url: `${process.env.REACT_APP_LOCAL_URL}/get`,
      body: {
        url: `${getNetsuiteURL()}&functionName=getTeams`,
      },
    });
    // create new array of teams to set
    const teamsToSet = [...data];
    // add default team to first index
    teamsToSet.unshift({
      value: 'DEFAULT',
      text: 'Select Team...',
    });
    // update the teams to display in the drop down menu and reassign the list of engineers to only display the default
    // engineer
    const engineersToSet = [defaultEngineer];
    setTeams(teamsToSet);
    setTeamsToAssign(teamsToSet);
    setEngineers(engineersToSet);
    // setEngineersToSelect(engineersToSet);
  };

  /**
   * inbuilt react hook - called with a dependency on the markers length so will be called each time the list of markers
   * changes
   */
  useEffect(() => {
    // only fetch the info if markers are present on map and no teams exist as assignment should
    // should only be available when markers exist on the map
    if (
      markers.length > 0 &&
      teams.length === 0 &&
      mapType !== 'cold_call'
    ) {
      setProcessing(true);
      // fetch team data and set the redux state with the returned teams
      fetchTeams().then(() => {
        setProcessing(false);
      });
    }
  }, [markers.length]);

  if (mapType === 'cold_call') {
    /**
     * only add this useEffect if the map is the cold call map - called with an empty dependency array so will only be
     * called when the component initially renders
     */
    useEffect(() => {
      // fetch the list of teams
      fetchTeams().then(() => {
        setProcessing(false);
      });
    }, []);
  }

  // props that are the same across all fields
  const defaultFilterProps = {
    openFilter,
    setOpenFilter,
    tabIndex: 0,
    mapType,
  };

  /**
   * function to return a filter dependant on the type of filter. will also assign all props required
   * @param {Object} filter - current filter to render
   * @param {Number} key - key to use as react key (react requires keys on lists of components)
   * @returns {JSX.Element|null} the element to render
   */
  const renderInput = (filter, key) => {
    // assign key
    defaultFilterProps.key = key;
    // assign the options based on the filterOptionsMapping and the filter id
    defaultFilterProps.options = filterOptionMapping[filter.id];
    // assign the onChange function
    defaultFilterProps.onChange =
      handleChangeMapping[filter.onChangeMappingKey];
    // assign the default selected value - if the id is noCases then we are just wanting the number of selected cases
    // else assign the corresponding value in the filters object above
    defaultFilterProps.selectedValue =
      filter.id === 'noCases'
        ? selectedCases.length.toString()
        : filters[filter.id];
    // assign the setPopupData function to allow the menu to show a popup box
    defaultFilterProps.setPopupData = setPopupData;
    // assign specific props to poNumbers multi select menu
    if (filter.id === 'poNumbers') {
      defaultFilterProps.access = access;
      defaultFilterProps.includeAssigned = includeAssigned;
      defaultFilterProps.setProcessing = setProcessing;
    }
    // should only be present on dropdown menus that have options defined in this component e.g. engineer / team menu
    if (filter.optionMapping) {
      defaultFilterProps.options =
        filterOptionMapping[filter.optionMapping];
    }
    // we will use the options in this case to use as the values that are displayed in the dropdown menu
    // so set as empty array which can have values added
    if (!filter.optionMapping && filter.type === 'multiselect') {
      defaultFilterProps.options = [];
    }
    // return the relevant input type dependant on the filter.type
    switch (filter.type) {
      case 'checkbox':
        return <Checkbox {...filter} {...defaultFilterProps} />;
      case 'select':
        return <DropDownMenu {...filter} {...defaultFilterProps} />;
      case 'date':
        return <DatePicker {...filter} {...defaultFilterProps} />;
      case 'text':
        return <TextInput {...filter} {...defaultFilterProps} />;
      case 'multiselect':
        return <MultiSelect {...filter} {...defaultFilterProps} />;
      default:
        return null;
    }
  };

  // RENDER
  return (
    <div id="filters_container">
      <div className="left">
        {displayFilters.map((filter, index) =>
          renderInput(filter, index),
        )}
        {markers.length > 0 &&
          markerDisplayFilters.map((filter, index) =>
            renderInput(filter, index),
          )}
      </div>
      <div className="right">
        <RefreshButton
          mapType={mapType}
          setData={setData}
          map={map}
          filters={filters}
          setProcessing={setProcessing}
          processing={processing}
          className="basic"
          refreshType={refreshType}
          setRefreshType={setRefreshType}
          setPopupData={setPopupData}
          setLocalProcessing={setLocalProcessing}
          localProcessing={localProcessing}
        />
        {markers.length > 0 && (
          <RefreshButton
            mapType={mapType}
            setData={setData}
            map={map}
            filters={filters}
            setProcessing={setProcessing}
            processing={processing}
            className="full"
            refreshType={refreshType}
            setRefreshType={setRefreshType}
            setPopupData={setPopupData}
            setLocalProcessing={setLocalProcessing}
            localProcessing={localProcessing}
          />
        )}
        {markers.length > 0 && (
          <AssignButton
            setData={setData}
            access={access}
            date={date}
            engineer={engineer}
            includeAssigned={includeAssigned}
            poNumber={poNumber}
            selectedCases={selectedCases}
            setProcessing={setProcessing}
            team={team}
            currentPolygon={currentPolygon}
            map={map}
            setPopupData={setPopupData}
            engineers={engineers}
            mapType={mapType}
            poNumbers={poNumbers}
            setLocalProcessing={setLocalProcessing}
            selectedEngineers={selectedEngineers}
            plannerGroup={plannerGroup}
            teamToAssign={teamToAssign}
            refreshType={refreshType}
          />
        )}
      </div>
      <MapBlocker
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
      />
      <ContentBlocker
        processing={processing}
        localProcessing={localProcessing}
      />
      <PopupWindow
        message={popupMessage}
        title={popupTitle}
        className={popupClassName}
        display={display}
        callback={callback}
      />
    </div>
  );
};
Filters.propTypes = {
  markers: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  map: PropTypes.object,
  selectedCases: PropTypes.array.isRequired,
  currentPolygon: PropTypes.object,
  displayFilters: PropTypes.array.isRequired,
  markerDisplayFilters: PropTypes.array,
  accessOptions: PropTypes.array.isRequired,
  mapType: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  setProcessing: PropTypes.func.isRequired,
  refreshType: PropTypes.string.isRequired,
  setRefreshType: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  map: null,
  currentPolygon: null,
  mapType: null,
  markerDisplayFilters: [],
};
export default Filters;
