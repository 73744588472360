import React from 'react';
import {
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import '../../Styles/DropDownMenuStyles.scss';

const DropDownMenu = ({
  // options = the options to display in the drop down menu
  options,
  // selectedValue = the current selected value for this field
  selectedValue,
  // onChange = the callback function that will update the filter state with the new value
  onChange,
  // id = the id of the field. Will be used to update the open filter state and the filter state. This is the key for this field in the filters object
  id,
  // label = the label to display alongside the field
  label,
  // tabIndex = html tabIndex to allow focusing via the tab key
  tabIndex,
  // openFilter = current open filter
  openFilter,
  // setOpenFilter = callback function to set the open filter so we can hide other open filters once this one is clicked
  setOpenFilter,
}) => {
  // store whether we should display the dropdown menu content in a variable for easy access
  const showContent = openFilter === id;
  /**
   * function to handle clicking a value in the dropdown menu
   * @param {Event} event - the html event
   * @param {HTMLElement} event.target - the actual html element that was clicked
   */
  const handleClick = (event) => {
    // get the value stored in the html elements data-value property
    const value = event.target.getAttribute('data-value');
    // update the filter state using the onChange callback
    onChange(value, id);
    // reset the open filters so that this dropdown menu will close once the value is selected
    setOpenFilter('');
  };
  /**
   * function to open/close the menu when the header area is clicked
   */
  const openMenu = () => {
    const filterToSet = openFilter === id ? '' : id;
    setOpenFilter(filterToSet);
  };
  // get the selected node (the currently selected value in the dropdown menu) so that we can highlight it as selected
  const selectedNode = options.filter(
    (option) => option.value.toString() === selectedValue.toString(),
  )[0];
  // get the current text from the current node so that we can display it in the header section of the dropdown
  const currentText = selectedNode ? selectedNode.text : '';

  // RENDER
  return (
    <div className="dropdown_menu_container">
      <div className="title">{label}</div>
      <div className="header" onClick={openMenu} tabIndex={tabIndex}>
        <p>{currentText}</p>
        {/* wee bit of UI flair to display a different dropdown icon depending on if it is open or closed */}
        <FontAwesomeIcon
          icon={showContent ? faCaretUp : faCaretDown}
        />
      </div>
      {/* only display this section if we should show the options of the dropdown menu */}
      {showContent && (
        <div className="content">
          {options.map((option) => (
            <div
              // classname will be option selected if the current option is the currently selected value
              className={`option ${
                selectedValue.toString() === option.value.toString()
                  ? 'selected'
                  : ''
              }`}
              // store the value in the data-value property
              data-value={option.value}
              key={option.value}
              onClick={handleClick}
            >
              {option.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DropDownMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func,
};
DropDownMenu.defaultProps = {
  selectedValue: null,
  tabIndex: null,
  openFilter: null,
  setOpenFilter: null,
};

export default DropDownMenu;
