import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NotFound from './404NotFound';
import {
  _formatAndGroupCaseData,
  _formatCaseDataWithoutGrouping,
} from '../Helpers/Format';
import Filters from '../Components/Filters';
import MapComponent from '../Components/GoogleMap';

const ColdCall = ({
  match: {
    params: { apiKey },
  },
}) => {
  const { NODE_ENV, REACT_APP_NS_KEY } = process.env;
  // return the not found component if client should not access page (shouldn't really be triggered due the check in
  // App.jsx, but added as precaution
  if (NODE_ENV !== 'development' && apiKey !== REACT_APP_NS_KEY) {
    return <NotFound />;
  }
  // set initial state [value, setValue function]
  // caseData, stores all case data
  const [caseData, setCaseData] = useState([]);
  // map = the google map
  const [map, setMap] = useState(null);
  // zoom = zoom level of the map
  const [zoom, setZoom] = useState(7);
  // refreshType = type of refresh - value changes based on the type of refresh button that was pressed
  const [refreshType, setRefreshType] = useState('basic');
  // selectedCases = the cases selected via the polygon tool
  const [selectedCases, setSelectedCases] = useState([]);
  // currentPolygon = the current completed polygon, used so we can remove when a new polygon is created
  const [currentPolygon, setCurrentPolygon] = useState(null);
  // processing = whether to throw up the white click blocker - will be set when fetching data
  const [processing, setProcessing] = useState(false);

  // options to display in the access dropdown menu
  const accessOptions = [
    { value: 'DEFAULT', text: 'Select Access...' },
    { value: 'INSIDE', text: 'Inside' },
    { value: 'OUTSIDE', text: 'Outside' },
  ];

  // list of filters to always display in the Filters component (passed in as props)
  const displayFilters = [
    {
      id: 'includeAssigned',
      onChangeMappingKey: 'normal',
      label: 'Include Assigned',
      type: 'checkbox',
      disabled: false,
    },
    {
      id: 'plannerGroup',
      onChangeMappingKey: 'normal',
      label: 'Planner Group',
      type: 'text',
      disabled: false,
    },
    {
      id: 'access',
      onChangeMappingKey: 'normal',
      label: 'Access',
      type: 'select',
      disabled: false,
      defaultLabel: 'Select Access',
    },
    {
      id: 'poNumbers',
      onChangeMappingKey: 'normal',
      label: 'PO Number(s)',
      type: 'multiselect',
      disabled: false,
      inputType: 'text',
      placeholder: 'Enter PO Number',
    },
    {
      id: 'team',
      label: 'Assigned To Team',
      onChangeMappingKey: 'fetchEngineers',
      disabled: false,
      type: 'select',
      defaultLabel: 'Select Team',
    },
    {
      id: 'selectedEngineers',
      label: 'Assigned To Engineer',
      onChangeMappingKey: 'normal',
      disabled: false,
      type: 'multiselect',
      inputType: 'select',
      placeholder: 'Search Engineer',
      optionMapping: 'selectedEngineers',
    },
    {
      id: 'configCode',
      onChangeMappingKey: 'normal',
      label: 'Meter Config Code',
      type: 'text',
      disabled: false,
    },
  ];

  const markersDisplayFilters = [
    {
      id: 'teamToAssign',
      label: 'Team To Assign',
      onChangeMappingKey: 'fetchEngineers',
      type: 'select',
      disabled: false,
      defaultLabel: 'Select Team',
    },
    {
      id: 'engineer',
      label: 'Engineer To Assign',
      onChangeMappingKey: 'normal',
      type: 'select',
      disabled: false,
      defaultLabel: 'Select Engineer',
    },
    {
      id: 'noCases',
      label: 'Number Of Cases',
      onChangeMappingKey: 'normal',
      type: 'text',
      disabled: true,
    },
    {
      id: 'date',
      onChangeMappingKey: 'normal',
      label: 'Date To Assign',
      type: 'date',
      disabled: false,
    },
  ];
  /**
   * function to update the case data and format it into the correct format we need to display
   * pins
   * @param {Object[]} data - raw list of cases to render
   * @param {String} typeOfRefresh - which button was pressed to refresh the data - will determine what kind of
   *                                 data formatting we require
   * @return {void}
   */
  const setData = (data, typeOfRefresh) => {
    let formattedData;
    // we need to group the cases together based on lat/lng into single pins if the refresh type is full
    if (typeOfRefresh === 'full') {
      formattedData = _formatAndGroupCaseData(data);
    } else {
      // else just return all cases as single objects, as we don't care how many pins are rendered on top of
      // each other as we use a MarkerClusterer
      formattedData = _formatCaseDataWithoutGrouping(data);
    }
    // reset the current polygon if it exists
    if (currentPolygon) {
      currentPolygon.setMap(null);
    }
    // set the caseData state variable
    setCaseData(formattedData);
    // update the refresh type as well
    setRefreshType(typeOfRefresh);
    setSelectedCases([]);
  };
  // RENDER
  return (
    <div
      className="App"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div className="filter_container">
        <Filters
          setData={setData}
          markers={caseData}
          map={map}
          selectedCases={selectedCases}
          currentPolygon={currentPolygon}
          displayFilters={displayFilters}
          accessOptions={accessOptions}
          mapType="cold_call"
          setProcessing={setProcessing}
          processing={processing}
          refreshType={refreshType}
          markerDisplayFilters={markersDisplayFilters}
          setRefreshType={setRefreshType}
        />
      </div>
      <div className="map_container">
        <MapComponent
          setData={setData}
          markers={caseData}
          map={map}
          setMap={setMap}
          currentPolygon={currentPolygon}
          setPolygon={setCurrentPolygon}
          zoom={zoom}
          setZoom={setZoom}
          selectedCases={selectedCases}
          setSelectedCases={setSelectedCases}
          refreshType={refreshType}
          processing={processing}
          mapType="cold_call"
        />
      </div>
    </div>
  );
};

ColdCall.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ColdCall;
