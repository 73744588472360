import React from 'react';
import PropTypes from 'prop-types';
import CustomMarker from './Marker';

// render all pins on map
const UnClusteredPins = ({ markers }) =>
  markers.map(
    ({ latLng, description, number, assigned, inside }, index) => {
      let color = 'green.png';
      if (assigned?.length > 0) {
        color = 'blue.png';
      } else if (inside) {
        color = 'red.png';
      }
      return (
        <CustomMarker
          description={description}
          position={latLng}
          key={index}
          color={color}
        />
      );
    },
  );

UnClusteredPins.propTypes = {
  markers: PropTypes.array.isRequired,
};

export default UnClusteredPins;
