import React, { useEffect, useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import '../Styles/CustomMarkerStyles.scss';

const CustomMarker = ({
  // position of the marker
  position,
  // description = the list of paragraphs to render in the infowindow (mprn numbers)
  description,
  // the clusterer (if exists) we are using on the map
  clusterer,
  // pin color
  color,
}) => {
  // state to determine whether we should display the info window containing the mprn numbers
  const [isOpen, setIsOpen] = useState(false);
  // when the marker is first rendered check if the content blocker is present and if this marker is open
  // and if so, close it
  useEffect(() => {
    const contentBlocker = document.querySelector('#content_blocker');
    if (contentBlocker && isOpen) {
      setIsOpen(false);
    }
  }, []);
  // if we are not clustering the pins then we should give the option to display the info window
  // otherwise just display the marker on its own, with no logic
  // RENDER
  return !clusterer ? (
    <Marker
      position={position}
      onClick={() => setIsOpen(!isOpen)}
      clusterer={clusterer}
      icon={{
        url: `http://maps.google.com/mapfiles/ms/icons/${color}`,
      }}
    >
      {isOpen && (
        <InfoWindow>
          <div className="marker_content">{description}</div>
        </InfoWindow>
      )}
    </Marker>
  ) : (
    <Marker
      position={position}
      onClick={() => setIsOpen(!isOpen)}
      clusterer={clusterer}
      icon={{
        url: `http://maps.google.com/mapfiles/ms/icons/${color}`,
      }}
    />
  );
};

CustomMarker.propTypes = {
  position: PropTypes.object.isRequired,
  description: PropTypes.array,
  clusterer: PropTypes.object,
  color: PropTypes.string.isRequired,
};

CustomMarker.defaultProps = {
  clusterer: null,
  description: [],
};

export default CustomMarker;
