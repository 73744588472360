import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import './Styles/App.scss';
import TeamAssignment from './Views/TeamAssignment';
import NotFound from './Views/404NotFound';
import MultiSOCleanup from './Views/MultiSOCleanup';
import ColdCall from './Views/ColdCall';

function App() {
  const { NODE_ENV } = process.env;
  // render different routes depending on environment. Allows easy dev work on localhost
  // without requiring the API key
  return NODE_ENV === 'development' ? (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <TeamAssignment {...props} />}
        />
        <Route
          exact
          path="/cleanup/"
          render={(props) => <MultiSOCleanup {...props} />}
        />
        <Route
          exact
          path="/coldcall/"
          render={(props) => <ColdCall {...props} />}
        />
      </Switch>
    </Router>
  ) : (
    <Router>
      <Switch>
        <Route
          exact
          path="/:apiKey"
          render={(props) => <TeamAssignment {...props} />}
        />
        <Route
          exact
          path="/cleanup/:apiKey"
          render={(props) => <MultiSOCleanup {...props} />}
        />
        <Route
          exact
          path="/coldcall/:apiKey"
          render={(props) => <ColdCall {...props} />}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
