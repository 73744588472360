import React from 'react';
import { _generateUniqueKey } from './GenerationHelper';

const { REACT_APP_ENV_MODE } = process.env;

// based on the env mode (production|development|staging) we want to assign a different account id
// then we can use interpolation to get the netsuite starting url for support cases
// this will be passed into the map callout so that a user can navigate to the support case straight form the pin
let accountId;
switch (REACT_APP_ENV_MODE) {
  case 'production':
    accountId = '5251372';
    break;
  default:
    accountId = '2532088';
}

const nsStartingUrl = `https://${accountId}.app.netsuite.com/app/crm/support/supportcase.nl?`;

/**
 * function to format the list of cases without grouping them together. will just convert the raw case data into a list of pins
 * this will be used when we use the marker clusterer as we dont care about the different mprns for the same latlng, we only care about
 * how many pins are in an area
 * @param {Object[]} cases - the raw list of cases returned by netsuite
 * @return {Object[]} the new formatted list of cases with their latlng to be displayed in the google map
 * @private
 */
export const _formatCaseDataWithoutGrouping = (cases) => {
  const formattedData = [];
  // loop through the list of cases and add each one the the formattedData array as a new object containing the latlng info
  cases.forEach((nsCase) => {
    const currentLat = nsCase.custevent_esb_real_lat;
    const currentLng = nsCase.custevent_esb_real_long;
    const { assigned, id } = nsCase;
    formattedData.push({
      latLng: {
        lat: parseFloat(currentLat),
        lng: parseFloat(currentLng),
      },
      ids: [id],
      assigned,
    });
  });
  return formattedData;
};
/**
 * function to format and group each case by latlng. will be used when we are NOT using the marker clusterer and we need
 * to be more accurate with which mprns are in the same location
 * @param {Object[]} cases - the raw list of cases returned by netsuite
 * @return {Object[]} the new formatted list of cases with their latlng to be displayed in the google map
 * @private
 */
export const _formatAndGroupCaseData = (cases) => {
  const formattedData = [];
  const existingKeys = [];
  let currentKey = 0;
  cases.forEach((nsCase) => {
    const currentLat = nsCase.custevent_esb_real_lat;
    const currentLng = nsCase.custevent_esb_real_long;
    const number = nsCase.custevent_esb_so_mprn;
    const inside = nsCase.custevent_esb_meter_inside;
    const { assigned, id } = nsCase;
    const uniqueKey = currentLat.toString() + currentLng.toString();
    const indexOf = existingKeys.indexOf(uniqueKey);
    // if the current latlng doesnt already exist in the list of existing keys then add a new object to the list of formattedData
    if (indexOf === -1) {
      formattedData.push({
        latLng: {
          lat: parseFloat(currentLat),
          lng: parseFloat(currentLng),
        },
        // the description can be passed in as actual html/jsx tags
        description: [
          <a
            href={`${nsStartingUrl}id=${id}&whence=`}
            key={_generateUniqueKey(currentKey)}
            rel="noreferrer"
            target="_blank"
          >
            {number}
          </a>,
        ],
        ids: [id],
        assigned,
        inside,
      });
      // add to the list of existing keys to serve as duplicate comparison
      existingKeys.push(uniqueKey);
      currentKey++;
    } else {
      // if the current latlng does already exist in the list of cases, grab the corresponding object in the formattedData
      // and add an extra entry into its ids and description
      formattedData[indexOf].ids.push(id);
      formattedData[indexOf].description.push(
        <br key={currentKey} />,
      );
      formattedData[indexOf].description.push(
        <a
          rel="noreferrer"
          target="_blank"
          href={`${nsStartingUrl}id=${id}&whence=`}
          key={_generateUniqueKey(currentKey)}
        >
          {number}
        </a>,
      );
      currentKey += 2;
    }
  });
  return formattedData;
};

export const formatDate = (dd) => {
  const d = new Date(dd);
  let months = d.getMonth() + 1;
  if (months < 10) {
    months = `0${months}`;
  }
  let days = d.getDate();
  if (days < 10) {
    days = `0${days}`;
  }
  const year = d.getFullYear();
  return `${days}/${months}/${year}`;
};
