import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { getNetsuiteURL, makeRequest } from '../Helpers/Requests';
import { formatDate } from '../Helpers/Format';

const AssignButton = ({
  // setProcessing = callback function to update the processing status (whether to throw up the click blocker to prevent double clicks)
  setProcessing,
  // engineer = engineer to assign cases to
  engineer,
  // team = team to assign cases to
  team,
  // date = date to assign
  date,
  // selectedCases = the list of cases to assign to the engineer
  selectedCases,
  // includeAssigned = whether to include cases which have already been assigned in the response once the data has been updated
  // (after assignment, once we re-request the data)
  includeAssigned,
  // currentPolygon = the current polygon drawn on the map
  currentPolygon,
  // setData = callback function to update the data state (not used)
  setData,
  // access = outdoor or indoor to send to netsuite to filter cases (after assignment, once we re-request the data)
  access,
  // poNumber = the po number to send to netsuite to filter cases (after assignment, once we re-request the data)
  poNumber,
  // map = current google map object
  map,
  // setPopupData = callback to update the popup window information
  setPopupData,
  // engineers = the list of engineers to be used to get the name of the engineer when displaying the popup confirmation message
  engineers,
  // mapType = type of map e.g. cleanup, cold_call, team_assignment
  mapType,
  // poNumbers = list of po numbers to send to netsuite to filter cases. Only applicable for some map types
  // (after assignment, once we re-request the data)
  poNumbers,
  refreshType,
  plannerGroup,
  teamToAssign,
}) => {
  /**
   * callback function to be called as the callback for the popup menu if an error has been triggered
   * basically just sets the menu to be hidden, sets processing to false and updates the refresh type to be the current refresh
   * type stored in the createRef above
   */
  const errorPopupCallback = () => {
    setPopupData({
      popupTitle: '',
      display: false,
      popupClassName: '',
      popupMessage: '',
      callback: null,
    });
    setProcessing(false);
  };

  /**
   * function to loop through the full list of engineers and return the correct name of
   * the engineer selected
   * @return {String} name of the engineer selected
   */
  const getEngineer = () =>
    engineers.filter(
      (e) => e.value.toString() === engineer.toString(),
    )[0].text;

  /**
   * function to retrieve the list of engineer availability for a given date
   * @param {Date} value - the date to get availability for
   * @return {Promise<Object>} the availability object, defined by netsuite
   */
  const getEngineerAvailability = async (value) =>
    makeRequest({
      body: {
        url: `${getNetsuiteURL()}&functionName=getEngineerAvailability`,
        engineer: value,
        date: formatDate(date),
      },
    });

  /**
   * function to validate the engineers availability
   * @return {Promise<{error: boolean, message: string}|{error: boolean}>}
   */
  const validateAssignmentOfOutdoorCases = async () => {
    // get the availability of the engineer and destructure like in other components
    const { data, error, message } = await getEngineerAvailability(
      engineer,
    );
    if (error) {
      return { error: true, message };
    }
    // go through the different validations and return an error object if necessary
    let errorMessage = '';
    let validationError = false;
    if (data.availableSlots < 0) {
      validationError = true;
      errorMessage = `${getEngineer()} has less than 0 slots available for ${formatDate(
        date,
      )}. This may mean there are duplicates for the same date/time.`;
      return { error: validationError, message: errorMessage };
    }
    if (data.availableSlots === 0) {
      validationError = true;
      errorMessage = `${getEngineer()} has no slots available for ${formatDate(
        date,
      )}.`;
      return { error: validationError, message: errorMessage };
    }
    if (selectedCases.length > data.availableSlots) {
      validationError = true;
      errorMessage = `There are only ${
        data.availableSlots
      } slot(s) available for ${getEngineer()} on ${formatDate(
        date,
      )}. You have selected ${selectedCases.length} cases.`;
      return { error: validationError, message: errorMessage };
    }
    // if no errors have been thrown, return error: false object
    return { error: false };
  };

  /**
   * function to make the assignment request to netsuite
   * @param {Object} body - the request body to send
   * @return {Promise<boolean>} return true if an error is thrown or false if not
   */
  const assignRequest = async (body) => {
    let functionName = 'assignCases';

    if (mapType === 'cold_call') {
      functionName = 'assignColdCallCases';
    }
    // for the cleanup map they can either assign outside or indoor cases, so we need to perform different logic if
    // it is an outdoor assignment
    if (mapType === 'cleanup' && access === 'OUTSIDE') {
      functionName = 'assignCleanupCases';
      // validate whether the cases can be assigned to the engineer
      const { error, message } =
        await validateAssignmentOfOutdoorCases();
      // if not, trigger the popup message
      if (error) {
        setPopupData({
          popupMessage: message,
          popupTitle: 'ERROR_ASSIGNING_CASES',
          popupClassName: 'error',
          display: true,
          callback: errorPopupCallback,
        });
        return true;
      }
    }

    // make the request, as there is no actual data to be returned here, we just need to check if there is an error
    const { error, message } = await makeRequest({
      body: {
        url: `${getNetsuiteURL()}&functionName=${functionName}`,
        ...body,
        caseIds: selectedCases,
        triggerMethod: 'Team Assignment Map',
      },
    });
    // if error, display popup message
    if (error === true) {
      setPopupData({
        popupMessage:
          typeof message === 'string'
            ? message
            : JSON.stringify(message),
        popupTitle: 'ERROR_ASSIGNING_CASES',
        popupClassName: 'error',
        display: true,
        callback: errorPopupCallback,
      });
      return true;
    }
    return false;
  };

  /**
   * function to request the data once the assignment process is complete
   * @return {Promise<{data, error: boolean}|{data, error: boolean}>} the data / error from netsuite
   */
  const refetchData = async () => {
    // debugger;
    // construct the filters to send to netsuite using the props passed in
    const constructedFilters = {
      access,
      includeAssigned,
      poNumber,
      date,
      bounds: map.getBounds().toJSON(),
      poNumbers,
      mapType,
      plannerGroup,
      engineers: [],
    };

    let functionName = 'getCases';
    if (mapType === 'cold_call') {
      // on cold call map only, reassign the function name
      functionName = 'getCasesColdCall';
      constructedFilters.engineer = engineer;
    }
    // request the data from netsuite and destructure response
    const { data, error, message } = await makeRequest({
      body: {
        ...constructedFilters,
        url: `${getNetsuiteURL()}&functionName=${functionName}`,
      },
    });
    // if error, display the popup and return {error: true}
    if (error === true) {
      setPopupData({
        popupMessage:
          typeof message === 'string'
            ? message
            : JSON.stringify(message),
        popupTitle: 'ERROR_ASSIGNING_CASES',
        popupClassName: 'error',
        display: true,
        callback: errorPopupCallback,
      });
      return { error: true };
    }
    return { error: false, data };
  };

  /**
   * callback function for the popup window
   * @param {Boolean} successVal - whether yes or no was clicked in the popup window
   * @return {Promise<void>}
   */
  const popupCallback = async (successVal) => {
    // instantly hide the popup once it has been clicked
    setPopupData({
      popupTitle: '',
      display: false,
      popupClassName: '',
      popupMessage: '',
    });
    // if yes was clicked to confirm the assignment
    if (successVal === true) {
      // get the date to assign formatted so netsuite will accept it
      const dateToAssign = formatDate(date);
      // construct the body of the request to send to netsuite to assign the cases
      const body = {
        engineer,
        team: mapType === 'cold_call' ? teamToAssign : team,
        date: dateToAssign,
      };
      // assign cases, which will return true if an error was thrown, or false if not
      const assignmentError = await assignRequest(body);
      // if error, stop processing
      if (assignmentError) {
        return;
      }
      // refetch the cases and store response in data/error variables
      const { data, error } = await refetchData();
      // if error, stop processing
      if (error) {
        return;
      }
      // remove the current polygon from the map
      currentPolygon.setMap(null);
      // update the data using the setData callback
      setData(data, refreshType);
    }
    // hide the click blocker window
    setProcessing(false);
  };

  /**
   * function to be added to the onClick property of the button
   * @return {Promise<void>}
   */
  const handleAssign = async () => {
    // instantly throw up the click blocker window
    setProcessing(true);
    // perform validation, and if there are any values missing that are required, display the popup window and stop processing
    if (!date) {
      setPopupData({
        popupMessage: 'Please select a date to assign.',
        popupTitle: 'MISSING_VALUE',
        display: true,
        popupClassName: 'error',
        callback: popupCallback,
      });
      // createAlert('Please select a date to assign.');
      return;
    }
    if (
      (!team || team === 'DEFAULT') &&
      (!teamToAssign || teamToAssign === 'DEFAULT')
    ) {
      setPopupData({
        popupMessage: 'Please select a team & engineer to assign',
        popupTitle: 'MISSING_VALUE',
        display: true,
        popupClassName: 'error',
        callback: popupCallback,
      });
      // createAlert('Please select a team & engineer to assign');
      return;
    }
    if (!engineer || engineer === 'DEFAULT') {
      setPopupData({
        popupMessage: 'Please select an engineer to assign',
        popupTitle: 'MISSING_VALUE',
        display: true,
        popupClassName: 'error',
        callback: popupCallback,
      });
      // createAlert('Please select an engineer to assign');
      return;
    }
    if (selectedCases.length === 0) {
      setPopupData({
        popupMessage: 'Please select cases to be assigned',
        popupTitle: 'MISSING_VALUE',
        display: true,
        popupClassName: 'error',
        callback: popupCallback,
      });
      // createAlert('Please select cases to be assigned');
      return;
    }
    // if no errors have been thrown, display the popup window with a confirmation to allow user to click yes or no
    // to perform assignment
    setPopupData({
      popupMessage: `Are you sure you want to assign ${getEngineer()} to these ${
        selectedCases.length
      } cases on ${formatDate(date)}?`,
      popupTitle: 'CONFIRMATION',
      display: true,
      popupClassName: 'confirm',
      callback: popupCallback,
    });
  };

  // RENDER
  return (
    <div className="assign_button">
      <div className="label">
        <p>Assign</p>
      </div>
      <div className="button">
        <FontAwesomeIcon
          icon={faCheckCircle}
          onClick={handleAssign}
          size="2x"
        />
      </div>
    </div>
  );
};

AssignButton.propTypes = {
  setProcessing: PropTypes.func.isRequired,
  engineer: PropTypes.string.isRequired,
  team: PropTypes.string.isRequired,
  date: PropTypes.object,
  selectedCases: PropTypes.array.isRequired,
  includeAssigned: PropTypes.bool.isRequired,
  currentPolygon: PropTypes.object,
  map: PropTypes.object,
  setData: PropTypes.func.isRequired,
  setPopupData: PropTypes.func.isRequired,
  access: PropTypes.string.isRequired,
  poNumber: PropTypes.string.isRequired,
  engineers: PropTypes.array.isRequired,
  refreshType: PropTypes.string.isRequired,
  mapType: PropTypes.string,
  poNumbers: PropTypes.array,
  plannerGroup: PropTypes.string,
  teamToAssign: PropTypes.string,
};

AssignButton.defaultProps = {
  currentPolygon: null,
  map: null,
  date: null,
  mapType: null,
  poNumbers: null,
  plannerGroup: null,
  teamToAssign: null,
};

export default AssignButton;
