import React, { createRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { getNetsuiteURL, makeRequest } from '../Helpers/Requests';

const RefreshButton = ({
  // setData = callback function to update the list of cases
  setData,
  // map = current google map object
  map,
  // filters = filters object containing the live values selected in the filter fields
  filters,
  // setProcessing = callback function to update the processing status (whether to throw up the click blocker to prevent double clicks)
  setProcessing,
  // processing = if we are currently processing a request
  processing,
  // className = what type of refresh button we are using e.g full, basic
  className,
  // refreshType = the current refresh type that has been pressed e.g. full, basic
  refreshType,
  // setRefreshType = callback to update the refresh type
  setRefreshType,
  // setPopupData = callback to update the data displayed within the popup message, and allows triggering it to appear
  setPopupData,
  // localPorcessing = a test I was running to try to optimise the map, can be ignored
  localProcessing,
  // setLocalProcessing - same as above
  setLocalProcessing,
  // mapType = the type of map. e.g. cold_call, team_assignment, cleanup
  mapType,
}) => {
  // destructure the filters object so they can be referenced by variable
  const {
    access,
    includeAssigned,
    poNumber,
    date,
    poNumbers,
    selectedEngineers,
    plannerGroup,
    configCode,
    secondWave,
  } = filters;
  // create a ref for the current refresh type so it can be accessed easily
  const currentRefreshType = createRef();

  /**
   * callback function to be called as the callback for the popup menu if an error has been triggered
   * basically just sets the menu to be hidden, sets processing to false and updates the refresh type to be the current refresh
   * type stored in the createRef above
   */
  const errorPopupCallback = () => {
    setPopupData({
      popupTitle: '',
      display: false,
      popupClassName: '',
      popupMessage: '',
      callback: null,
    });
    setProcessing(false);
    setLocalProcessing(false);
    setRefreshType(currentRefreshType.current);
  };

  /**
   * function to actually perform the refresh depending on the successVal passed in
   * @param {Boolean} successVal - whether to process or not (true or false)
   * @returns {Promise<void>}
   */
  const performRefresh = async (successVal) => {
    // assign the current refresh type to be the type passed in as a prop
    const currentRefresh = refreshType;
    if (setPopupData && className === 'full') {
      // if we need to, reset the popup data to its default state, with display being false
      setPopupData({
        popupMessage: '',
        popupTitle: '',
        display: false,
        popupClassName: '',
        callback: null,
      });
    }
    if (successVal === true) {
      // reconstruct the filters so that they can be sent to netsuite
      const constructedFilters = {
        // for reference, this is the exact same as access: access, just a shorthand way of writing
        access,
        includeAssigned,
        poNumber,
        date,
        bounds: map.getBounds().toJSON(),
        poNumbers,
        mapType,
        plannerGroup,
        configCode,
        secondWave,
      };

      console.log('MAP TYPE', mapType);

      // assign default function name
      let functionName = 'getCases';

      if (mapType === 'cold_call') {
        // on cold call map only, reassign the function name
        functionName = 'getCasesColdCall';
        // and update the list of engineers to only use their value as the engineers field will be multiple
        // select for this map and will be passed in as an array of objects e.g. convert {value: 1, text: 'engineer 1'} to 1
        constructedFilters.engineers = selectedEngineers.map(
          (engineer) => engineer.value,
        );
      }

      setLocalProcessing(true);
      console.log('URL', getNetsuiteURL());
      // the data structure sent back from netsuite will either be {data: <data>} or if an error is thrown it will be {error: <error>, message: <message>}
      // so destructure the response made to netsuite
      const { data, error, message } = await makeRequest({
        body: {
          ...constructedFilters,
          url: `${getNetsuiteURL()}&functionName=${functionName}`,
        },
      });

      // if error display the popup message and stop processing
      if (error) {
        const popupMessage =
          typeof message === 'string'
            ? message
            : JSON.stringify(message);
        setPopupData({
          popupMessage,
          popupTitle: 'ERROR_GETTING_CASES',
          popupClassName: 'error',
          callback: errorPopupCallback,
          display: true,
        });
        return;
      }

      // if no error then set the processing to be true and then update the case data using the setData callback
      setProcessing(true);
      setData(data, className);
    } else {
      // if the successVal is false, just set the current refresh type
      setRefreshType(currentRefresh);
    }
    // once logic is complete, hide the click blockers
    setProcessing(false);
    setLocalProcessing(false);
  };
  /**
   * function to determine whether we should display a popup before refreshing, or just go ahead and refresh
   * @returns {Promise<void>}
   */
  const handleRefresh = async () => {
    // we never want to perform the logic if we are already processing
    if (processing || localProcessing) {
      return;
    }
    // update the currentRefreshType ref
    currentRefreshType.current = refreshType;
    // set the refreshType to be this buttons className e.g. full or basic
    setRefreshType(className);
    // display click blockers
    setLocalProcessing(true);
    setProcessing(true);

    // we should only display the popup message if this refresh button is for a full refresh
    if (setPopupData && className === 'full') {
      setPopupData({
        popupMessage:
          'Are you sure you want to perform a full refresh? This may take longer and slow down the performance of the map.',
        popupTitle: 'CONFIRMATION',
        display: true,
        popupClassName: 'confirm',
        // the callback will be triggered when the yes or no button is clicked on the PopupWindow component
        callback: performRefresh,
      });
    } else {
      // we can just manually call this function and pass in the successVal as true, as we dont need to rely on the buttons value
      // to determine whether its a yes or no
      await performRefresh(true);
    }
  };

  // RENDER
  return (
    <div className={`refresh_button ${className}`}>
      {/* if we are processing and the refreshType is the same as our className then we should display the loading symbol */}
      {(processing || localProcessing) &&
        refreshType === className && (
          <div className="refresh no_click">
            <div className="label">
              <p>
                {className === 'basic'
                  ? 'Basic Refresh'
                  : 'Full Refresh'}
              </p>
            </div>
            <div className="button">
              <div className="lds-dual-ring" />
            </div>
          </div>
        )}
      {/* else we should just display the button in its normal state */}
      {(!processing || className !== refreshType) && (
        <div className="refresh">
          <div className="label">
            <p>
              {className === 'basic'
                ? 'Basic Refresh'
                : 'Full Refresh'}
            </p>
          </div>
          <div className="button">
            <FontAwesomeIcon
              icon={faSyncAlt}
              size="2x"
              onClick={handleRefresh}
            />
          </div>
        </div>
      )}
    </div>
  );
};

RefreshButton.propTypes = {
  setData: PropTypes.func.isRequired,
  map: PropTypes.object,
  filters: PropTypes.object.isRequired,
  setProcessing: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  refreshType: PropTypes.string.isRequired,
  mapType: PropTypes.string,
  setRefreshType: PropTypes.func.isRequired,
  setPopupData: PropTypes.func,
  localProcessing: PropTypes.bool.isRequired,
  setLocalProcessing: PropTypes.func.isRequired,
};

RefreshButton.defaultProps = {
  map: null,
  setPopupData: null,
  mapType: '',
};

export default RefreshButton;
