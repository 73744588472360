// eslint-disable-next-line import/prefer-default-export
export const getNetsuiteURL = () => {
  const {
    // development | production | staging
    REACT_APP_ENV_MODE,
    // the url to use when in production, will be the linode url
    REACT_APP_NS_URL_LIVE,
    // the url to use when in development, will be localhost
    REACT_APP_NS_URL_DEV,
    REACT_APP_NS_URL_STAGING,
  } = process.env;
  switch (REACT_APP_ENV_MODE) {
    case 'staging':
      return REACT_APP_NS_URL_STAGING;
    case 'production':
      return REACT_APP_NS_URL_LIVE;
    default:
      return REACT_APP_NS_URL_DEV;
  }
};
export const makeRequest = async ({ body }) => {
  // destructure the environment variables. these can be found in the .env file in the root directory
  const {
    // development | production | staging
    REACT_APP_ENV_MODE,
    // the url to use when in production, will be the linode url
    REACT_APP_PROD_URL,
    // the url to use when in development, will be localhost
    REACT_APP_DEV_URL,
    // the api key to allow access to the backend server
    REACT_APP_LOCAL_KEY,
  } = process.env;

  // get the url, will always end with /post as this is the way the node server is setup
  let url;
  switch (REACT_APP_ENV_MODE) {
    case 'development':
      url = `${REACT_APP_DEV_URL}/post`;
      break;
    default:
      url = `${REACT_APP_PROD_URL}/post`;
  }
  // construct the body to be sent in the request
  const newBody = {
    ...body,
    params: {
      apiKey: REACT_APP_LOCAL_KEY,
    },
  };
  // make the request and grab the response
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...newBody }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json();
  console.log('RESPONSE DATA: ', data);
  return data;
};
