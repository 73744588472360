import React from 'react';
import '../Styles/MapBlockerStyles.scss';
import PropTypes from 'prop-types';

const MapBlocker = ({
  // which filter is currently open
  openFilter,
  // callback to update the open filter
  setOpenFilter,
}) => {
  // list of select menus where this blocker should be taken into consideration
  const selects = [
    'access',
    'date',
    'engineer',
    'team',
    'poNumbers',
    'selectedEngineers',
    'teamToAssign',
  ];
  let filterIsOpen = false;
  /**
   * function to close all open filters. Resets openFilter to be ''.
   */
  const closeAllFilters = () => {
    setOpenFilter('');
  };
  // loop through all selects and if the current openFilter is the sames as one of them, we know there is a filter open
  selects.forEach((select) => {
    if (openFilter === select || openFilter === `${select}_values`) {
      filterIsOpen = true;
    }
  });
  // if a filter is open, return the map blocker to prevent accidental clicks on the map while the menu is open
  return filterIsOpen ? (
    <div className="map_blocker" onClick={closeAllFilters} />
  ) : null;
};

MapBlocker.propTypes = {
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func.isRequired,
};

MapBlocker.defaultProps = {
  openFilter: null,
};

export default MapBlocker;
