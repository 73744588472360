import React from 'react';
import PropTypes from 'prop-types';
import '../../Styles/Checkbox.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Checkbox = ({
  // onChange = callback function to handle the change of the value
  onChange,
  // selectedValue = the current selected value for the field
  selectedValue,
  // id = the id of the field. Will be used to update the open filter state and the filter state. This is the key for this field in the filters object
  id,
  // label = the label to display alongside the field
  label,
  // tabIndex = html tabIndex to allow focusing via the tab key
  tabIndex,
  // openFilter = current open filter
  openFilter,
  // setOpenFilter = callback function to set the open filter so we can hide other open filters once this one is clicked
  setOpenFilter,
}) => {
  /**
   * function to be called when the checkbox div is clicked
   * it will update the open filter to this checkbox id, which will in turn close any other open filters (fields, dropdowns etc.)
   */
  const handleChange = () => {
    // update the open filter to this id
    setOpenFilter(id);
    // call the onChange callback with the opposite value as the new value (e.g. if the current box is checked and we click, it should
    // be unchecked)
    onChange(!selectedValue, id);
  };
  // RENDER
  return (
    <div className="checkbox_container">
      <div className="title">{label}</div>
      <div
        className="checkbox"
        onClick={handleChange}
        tabIndex={tabIndex}
      >
        {/* if the checkbox value is true, display the tick icon, otherwise display nothing to give the UI appearance that it is unchecked */}
        {selectedValue && <FontAwesomeIcon icon={faCheck} />}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func,
};
Checkbox.defaultProps = {
  tabIndex: null,
  openFilter: null,
  setOpenFilter: null,
};

export default Checkbox;
