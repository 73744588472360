/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import {
  useJsApiLoader,
  GoogleMap,
  DrawingManager,
} from '@react-google-maps/api';
import ClusteredPins from './ClusteredPins';
import UnClusteredPins from './UnClusteredPins';

const containerStyle = {
  width: '99vw',
  height: '88vh',
};

const center = {
  lat: 53.39204,
  lng: -7.7824101,
};

const libraries = ['drawing'];

const MapComponent = ({
  // markers = the list of cases that need rendered on the map
  markers,
  // map = current map object (not used)
  map,
  // setData = callback function to update the data state (not used)
  setData,
  // setMap = callback function to update the map object state
  setMap,
  // currentPolygon = the current polygon object (if exists) on the map
  currentPolygon,
  // setPolygon = the callback function to update the current polygon object state
  setPolygon,
  // zoom = the level of zoom on the map
  zoom,
  // setZoom = the callback to update the zoom state (not used)
  setZoom,
  // selectedCases = the list of cases that fall within the confines of the current polygon (not used)
  selectedCases,
  // setSelectedCases = the callback function to update the list of cases within the current polygon
  setSelectedCases,
  // refreshType = the type of refresh, whether it is basic or full
  refreshType,
  // processing = whether we are current processing information
  processing,
  // mapType = the type of map. e.g. cold_call, cleanup, team_assignment
  mapType,
}) => {
  // list of maps where they shouldn't be able to draw (was previously cold_call, but this has been removed
  // keeping the variable in though in case we want to disable it for other maps in future)
  const mapsToDisableDrawing = [];
  // assign this as a variable for ease of use
  const disableDrawing = mapsToDisableDrawing.indexOf(mapType) !== -1;
  // setup provided by the react google maps api to load the map
  const { isLoaded } = useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCjYXR2za0P8jg0kux1CS9VUjXQUvcXY_8',
  });
  /**
   * function to be triggered when the map is loaded.
   * @param {Object} _map - the google map object
   */
  const onLoad = (_map) => {
    // update the map object state using the callback passed in as props.
    // This will allow access to this map object from elsewhere in the app
    setMap(_map);
  };

  /**
   * function to be triggered when a polygon is completed on the map
   * @param {Object} polygon - the polygon object
   */
  const completePolygon = (polygon) => {
    // clear current polygon so new one can be rendered
    if (currentPolygon) {
      currentPolygon.setMap(null);
    }
    // loop through each marker and if the position is within polygon coords, add each
    // case associated with marker to selected cases
    const _selectedCases = [];
    markers.forEach((marker) => {
      if (
        google.maps.geometry.poly.containsLocation(
          new google.maps.LatLng(marker.latLng),
          polygon,
        ) &&
        (!marker.assigned ||
          (marker.assigned && marker.assigned.length === 0) ||
          mapType === 'cold_call')
      ) {
        console.log('marker', marker);
        _selectedCases.push(...marker.ids);
      }
    });
    // set current polygon (used to remove when a new one is drawn)
    setPolygon(polygon);
    setSelectedCases(_selectedCases);
  };

  // RENDER
  return (
    // only render the map if loaded
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        options={{ gestureHandling: 'greedy' }}
      >
        {/* only render the drawing manager if we should NOT disable drawing */}
        {!disableDrawing && (
          <DrawingManager
            options={{
              drawingControl: true,
              drawingControlOptions: {
                ...{
                  position: google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [...['polygon']],
                },
              },
            }}
            onPolygonComplete={completePolygon}
          />
        )}
        {/* if we are not processing and the refresh type is basic, render the clustered pins (for optimal performance), else render the unclustered pins */}
        {!processing &&
          (refreshType === 'basic' ? (
            <ClusteredPins markers={markers} />
          ) : (
            <UnClusteredPins markers={markers} />
          ))}
      </GoogleMap>
    )
  );
};

MapComponent.propTypes = {
  markers: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  setMap: PropTypes.func.isRequired,
  map: PropTypes.object,
  currentPolygon: PropTypes.object,
  setPolygon: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  selectedCases: PropTypes.array.isRequired,
  setSelectedCases: PropTypes.func.isRequired,
  refreshType: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  mapType: PropTypes.string.isRequired,
};
MapComponent.defaultProps = {
  map: null,
  currentPolygon: null,
};

export default MapComponent;
