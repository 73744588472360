import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faCheck,
  faArrowsAltV,
} from '@fortawesome/free-solid-svg-icons';
import SelectedOption from './SelectedOption';
import '../../Styles/MultiSelectStyles.scss';
import { getNetsuiteURL, makeRequest } from '../../Helpers/Requests';

const MultiSelect = ({
  // the type of input e.g. text or select - if text then it is just a multiple text input with no options
  // but if it is select then it is a multiple select field
  inputType,
  // the options to display in the dropdown if inputType is select
  options,
  // label = the label to display alongside the field
  label,
  // selectedValue = the current selected value for the field
  selectedValue,
  // onChange = callback function to handle the change of the value
  onChange,
  // id = the id of the field. Will be used to update the open filter state and the filter state. This is the key for this field in the filters object
  id,
  // openFilter = current open filter
  openFilter,
  // setOpenFilter = callback function to set the open filter so we can hide other open filters once this one is clicked
  setOpenFilter,
  // placeholder = the placeholder value for the text input in the select menu dropdown field that allows you to filter options
  placeholder,
  // setPopupData = callback function to update the data to be displayed in the popup window
  setPopupData,
  // tabIndex = html tabIndex to allow focusing via the tab key
  tabIndex,
  // includeAssigned = whether to include cases which have already been assigned when we check the po number
  includeAssigned,
  // poNumber = the po number to send to netsuite to filter cases (after assignment, once we re-request the data)
  access,
  // setProcessing = callback function to update the processing status (whether to throw up the click blocker to prevent double clicks)
  setProcessing,
  // mapType = the type of map. e.g. cold_call, cleanup, team_assignment
  mapType,
}) => {
  // define the currentSearch state and the setter to allow entry in input field
  const [currentSearch, setCurrentSearch] = useState('');
  // this is whether to show the dropdown area that contains the list of values entered
  const isOpen = openFilter === `${id}_values`;
  // this is the current selected values to display in the dropdown area
  const currentSelected = selectedValue.map(({ value, text }) =>
    value ? value.toString() : text.toString(),
  );
  // the list of filtered current options. Initialised as an empty array but will be populated using the list of options passed in as props
  const [optionsToUse, setOptions] = useState([]);
  // create a ref that can be assigned to the text input area of the dropdown to allow us to focus on it below
  const focusRef = createRef();
  // whether to show the dropdown menu
  const showContent = openFilter === id;
  /**
   * function to handle the click of a new value
   * @param {String|Number} value - the id of the option object
   * @param {String} text - the display text of the option object
   */
  const handleClick = ({ value, text }) => {
    let newValues = selectedValue;
    // if the clicked value already exists in the list of selected options, then remove it
    if (currentSelected.indexOf(value.toString()) !== -1) {
      newValues = newValues.filter(
        (val) => val.value.toString() !== value.toString(),
      );
    } else {
      // otherwise push it into the list
      newValues.push({ value, text });
    }
    // reset the current search criteria
    setCurrentSearch('');
    // reset the list of optionsToUse to be the full list of options passed in as props
    setOptions(options);
    // update the filter state object with the onChange callback
    onChange({ [id]: [...newValues] }, id);
    // focus on the text input box, if the ref has been assigned
    focusRef.current?.focus();
  };
  /**
   * function to open/close the menu
   * @param {Boolean} addValuesSuffix - whether to add on the _value suffix or not
   */
  const openMenu = (addValuesSuffix) => {
    const filterToSet =
      openFilter === id || openFilter === `${id}_values`
        ? ''
        : `${id}${addValuesSuffix === true ? '_values' : ''}`;
    setOpenFilter(filterToSet);
  };
  /**
   * function to be called when a value should be removed from the list of selected values
   * @param {String|Number} value - the value to remove
   */
  const onRemove = (value) => {
    // filter out the value entered from the list of selectedValues
    const newValues = selectedValue.filter(
      (val) => val.text !== value,
    );
    // update the filter state object with the onChange callback
    onChange({ [id]: newValues }, id);
    if (newValues.length === 0) {
      setOpenFilter('');
    }
  };

  /**
   * callback function to be used in the popup menu
   */
  const popupCallback = () => {
    setPopupData({
      popupClassName: '',
      popupMessage: '',
      popupTitle: '',
      display: false,
      callback: null,
    });

    setProcessing(false);
  };
  /**
   * function to handle when a new value is entered in the search box of the input dropdown. Will validate the PO number
   * entered to make sure it exists on netsuite before allowing it to be entered
   * @param {Event} event - the HTML event object
   * @return {Promise<void>}
   */
  const handleEnter = async (event) => {
    // default behaviour is to try and perform a POST request and refresh the page, so prevent this from happening
    event.preventDefault();
    // throw up the click blocker page
    setProcessing(true);
    // if no value is entered, then display a popup message and stop processing
    if (!currentSearch) {
      setPopupData({
        popupClassName: 'error',
        popupMessage: 'Please enter a value',
        popupTitle: 'MISSING_VALUE',
        display: true,
        callback: popupCallback,
      });
      return;
    }
    // check if the entered text value already exists in the list of selectedValues
    const alreadyExistingValue = selectedValue.filter(
      (value) => value.text === currentSearch,
    );
    // if it already exists, display a popup message and stop processing
    if (alreadyExistingValue.length > 0) {
      setPopupData({
        popupClassName: 'error',
        popupMessage: `The value ${currentSearch} has already been added`,
        popupTitle: 'DUPLICATE_VALUE',
        display: true,
        callback: popupCallback,
      });
      return;
    }

    // cnostruct the filters to send to netsuite
    const filters = { includeAssigned, access, mapType };
    // check if the PO number exists in netsuite
    const { error, message } = await makeRequest({
      body: {
        ...filters,
        poNumber: currentSearch,
        url: `${getNetsuiteURL()}&functionName=checkPoNumber`,
      },
    });

    // if an error occurred with netsuite, show the popup window and stop processing
    if (error) {
      setPopupData({
        popupClassName: 'error',
        popupMessage: message,
        popupTitle: 'NO_CASES_FOR_PO_NUMBER',
        display: true,
        callback: popupCallback,
      });
      return;
    }

    // reset the current search criteria
    setCurrentSearch('');
    // use the onchange callback to update the filter state e.g. for the poNumbers field this would look like:
    // {poNumbers: [{text: 'test engineer', <the rest of the already existing selectedValues>}]
    onChange(
      { [id]: [{ text: currentSearch }, ...selectedValue] },
      id,
    );
    // hide the click blocker
    setProcessing(false);
    // focus on the input box if it exists
    if (focusRef.current) {
      focusRef.current.focus();
    }
  };
  /**
   * function to be called when the value of the text input box changes
   * @param {Event} event - the html event object
   * @param {HTMLElement} event.target - the actual html element that belongs to the event
   */
  const handleChange = (event) => {
    // grab the value from the html element
    const { value } = event.target;
    // update the current search state to be the value
    setCurrentSearch(value);
    // if we are on a multi select menu, then we should update the list of options to be all options that contain the
    // entered search term
    if (inputType === 'select') {
      const newOptions = options.filter(({ text }) =>
        text.toLowerCase().includes(value.toLowerCase()),
      );
      setOptions([...newOptions]);
    }
  };

  /**
   * function that is called every time the showContent field is changed
   */
  useEffect(() => {
    if (showContent && focusRef.current) {
      focusRef.current.focus();
    }
  }, [showContent]);

  /**
   * function to be called each time the options lenght changes
   */
  useEffect(() => {
    const newOptions = options;
    if (newOptions.length > 0 && newOptions[0].value === 'DEFAULT') {
      newOptions.shift();
    }
    setOptions([...newOptions]);
  }, [options]);
  // convert the list of selectedValues into an array of SelectedOption components
  const renderedValues = selectedValue.map(
    ({ text, value }, index) => (
      <SelectedOption
        onRemove={onRemove}
        value={text}
        text={text}
        key={index}
        includeRemove
      />
    ),
  );
  // we should only display the expand button on the menu if the input type is select and there is at least
  // 1 selected value
  const shouldDisplayExpandButton =
    inputType === 'select' && selectedValue.length !== 0;
  // if we should display the box that contains the list of values, opposed to the list of possible values
  const shouldDisplayValuesBox = isOpen && inputType === 'select';

  // RENDER
  return (
    <div className="multiple_select_container">
      <div className="title">{label}</div>
      {shouldDisplayExpandButton && (
        <div className="expand_button" onClick={() => openMenu(true)}>
          <FontAwesomeIcon icon={faArrowsAltV} />
        </div>
      )}
      <div className="header" tabIndex={tabIndex}>
        {shouldDisplayValuesBox > 0 && (
          <Collapse isOpened={isOpen}>
            <div className="values">{renderedValues}</div>
          </Collapse>
        )}
        {!isOpen && (
          <div className="values_in_menu">{renderedValues}</div>
        )}
        <div className="add" onClick={openMenu}>
          <FontAwesomeIcon icon={faPlus} data-fa-mask-id="comment" />
        </div>
      </div>
      {showContent && (
        <div className={`content ${inputType}`}>
          <div className="search_bar">
            <form className="input" onSubmit={handleEnter}>
              <input
                type="text"
                placeholder={placeholder}
                onChange={handleChange}
                ref={focusRef}
                value={currentSearch}
              />
            </form>
            {inputType !== 'select' && (
              <div className="search">
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={handleEnter}
                  id="fa_confirm"
                />
              </div>
            )}
          </div>
          <div className="options">
            {options.length === 0 &&
              selectedValue.map(({ text }, index) => (
                <SelectedOption
                  onRemove={onRemove}
                  text={text}
                  value={text}
                  inMenu
                  key={index}
                  includeRemove
                />
              ))}
            {optionsToUse.map(({ text, value }, index) => (
              <SelectedOption
                onRemove={onRemove}
                text={text}
                value={value}
                inMenu
                key={index}
                handleClick={handleClick}
                selected={
                  currentSelected.indexOf(value.toString()) !== -1
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

MultiSelect.propTypes = {
  inputType: PropTypes.string.isRequired,
  options: PropTypes.array,
  selectedValue: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func,
  setProcessing: PropTypes.func,
  setPopupData: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  tabIndex: PropTypes.number.isRequired,
  includeAssigned: PropTypes.bool,
  access: PropTypes.string,
  mapType: PropTypes.string,
};

MultiSelect.defaultProps = {
  options: [],
  selectedValue: [],
  openFilter: null,
  setOpenFilter: () => {},
  includeAssigned: false,
  access: 'DEFAULT',
  setProcessing: () => {},
  mapType: '',
};

export default MultiSelect;
