import React from 'react';
import PropTypes from 'prop-types';
import '../Styles/ContentBlockerStyles.scss';

// simple one liner helper component to throw up a click blocker to prevent double clicking buttons and such
// localProcessing can be ignored.
const ContentBlocker = ({ processing, localProcessing }) =>
  (processing || localProcessing) && <div id="content_blocker" />;

ContentBlocker.propTypes = {
  processing: PropTypes.bool.isRequired,
  localProcessing: PropTypes.bool.isRequired,
};

export default ContentBlocker;
