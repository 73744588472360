import React from 'react';
import PropTypes from 'prop-types';
import { MarkerClusterer } from '@react-google-maps/api';
import CustomMarker from './Marker';

const ClusteredPins = ({ markers }) => {
  // define the clusterer icons directory. Google will take this directory and auto assign the relevant color for the cluster
  const clustererOptions = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  };
  // RENDER
  return (
    // MarkerClusterer is provided by the react google maps api which allows us to group the pins together into clusters
    <MarkerClusterer
      options={{ ...clustererOptions }}
      gridSize={60}
      ignoreHidden
      enableRetinaIcons
    >
      {(clusterer) =>
        markers.map(
          ({ latLng, description, number, assigned }, index) => (
            <CustomMarker
              description={description}
              position={latLng}
              key={index}
              clusterer={clusterer}
              color={
                assigned && assigned.length > 0
                  ? 'blue.png'
                  : 'red.png'
              }
            />
          ),
        )
      }
    </MarkerClusterer>
  );
};

ClusteredPins.propTypes = {
  markers: PropTypes.array.isRequired,
};

export default ClusteredPins;
