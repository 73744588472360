import React from 'react';
import PropTypes from 'prop-types';
import '../../Styles/SelectOption.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

const SelectedOption = ({
  text,
  value,
  onRemove,
  inMenu,
  includeRemove,
  handleClick,
  selected,
}) => {
  const removeItem = (closeMenu) => {
    onRemove(value, closeMenu);
  };
  const onClick = () => {
    if (!includeRemove) {
      handleClick({ value, text });
    }
  };
  return (
    <div
      className={`selected_option ${
        inMenu ? 'in_menu' : 'not_in_menu'
      }`}
      onClick={onClick}
    >
      <div
        className={`value ${selected ? 'selected' : ''} ${
          !includeRemove && inMenu ? 'full' : 'small'
        }`}
      >
        {text}
      </div>
      {includeRemove && (
        <div className="close">
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={() => removeItem(!inMenu)}
          />
        </div>
      )}
    </div>
  );
};

SelectedOption.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  inMenu: PropTypes.bool,
  includeRemove: PropTypes.bool,
  handleClick: PropTypes.func,
  selected: PropTypes.bool,
};

SelectedOption.defaultProps = {
  inMenu: false,
  value: '',
  includeRemove: false,
  handleClick: () => {},
  selected: false,
};

export default SelectedOption;
