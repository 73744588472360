import React from 'react';
import PropTypes from 'prop-types';
import '../../Styles/TextInputStyles.scss';

const TextInput = ({
  onChange,
  id,
  selectedValue,
  label,
  tabIndex,
  disabled,
  openFilter,
  setOpenFilter,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value, id);
  };
  const closeOtherFilters = () => {
    setOpenFilter(id);
  };
  return (
    <div className="text_input_container">
      <div className="title">{label}</div>
      <input
        type="text"
        onChange={handleChange}
        value={selectedValue}
        onClick={closeOtherFilters}
        onFocus={closeOtherFilters}
        tabIndex={tabIndex}
        disabled={disabled}
        className={disabled ? 'disabled' : ''}
      />
    </div>
  );
};

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func,
};

TextInput.defaultProps = {
  tabIndex: null,
  disabled: false,
  openFilter: null,
  setOpenFilter: null,
};

export default TextInput;
